import { useHttpsCallable } from "react-firebase-hooks/functions";
import { createBrowserRouter } from "react-router-dom";
import ExpiredVerificationComponent from "../components/ExpiredVerificationComponent";
import PageNotFound from "../components/PageNotFound";
import VerificationComponent from "../components/VerificationComponent";
import { functions } from "./firebaseApp";

export const router = createBrowserRouter([
  {
    path: "veri/:token",
    element: <VerificationComponent />,
    // errorElement: <ExpiredVerificationComponent />,
  },
  {
    path: "/",
    element: <ExpiredVerificationComponent />,
    errorElement: <PageNotFound />,
  },
]);
