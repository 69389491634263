const { VStack, Heading } = require("@chakra-ui/react");

const PageNotFound = () => {
  return (
    <>
      <VStack>
        <Heading>
          Sorry! We could not find the page your are looking for
        </Heading>
      </VStack>
    </>
  );
};

export default PageNotFound;
