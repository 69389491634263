import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyCSGCooxSldCNf9SwDBEdQ-wiJJkIIdrOw",
  authDomain: "stripe-payment-link-generator.firebaseapp.com",
  projectId: "stripe-payment-link-generator",
  storageBucket: "stripe-payment-link-generator.appspot.com",
  messagingSenderId: "172786952869",
  appId: "1:172786952869:web:4262337ae8f7cafc2ffb4c",
  measurementId: "G-WHXKD84F6R",
};

const firebaseApp = initializeApp(firebaseConfig);

var firestore = getFirestore(firebaseApp);
const functions = getFunctions(firebaseApp);

// if (process.env.NODE_ENV === "development" || "testing") {
//   connectFirestoreEmulator(firestore, "localhost", 8080);
//   connectFunctionsEmulator(functions, "localhost", 5001);
// }

export { firestore, functions, firebaseApp };
