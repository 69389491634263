import { Heading, Text, VStack, Image, Spinner } from "@chakra-ui/react";
import FFD_BANNER from "../assets/da-banner.png";

const ExpiredVerificationComponent = () => {
  return (
    <>
      <VStack
        style={{
          background: "black",
          height: "100vh",
        }}
      >
        <Image
          src={FFD_BANNER}
          alt="Dutton Automotive Banner"
          fallback={<Spinner />}
          style={{
            width: "100%",
            // margin top based on screen size
            marginTop: "calc(30vh - 200px)",
            height: "auto",
            opacity: 0.8,
            objectFit: "cover",
          }}
        />
        <Text
          style={{
            background: "rgba(0, 0, 0, 0.8)",

            color: "white",
            padding: 40,
            // middle of the page
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Heading
            style={{
              color: "white",
              fontSize: "3rem",
              fontWeight: 300,
              // center
              textAlign: "center",
              marginBottom: 10,
            }}
          >
            Expired
          </Heading>
          We can't find that page! The link you followed has expired or is no
          longer valid.
          <br />
          <br />{" "}
          <a
            href="mailto:info@duttongroup.com.au"
            style={{
              color: "white",
              textDecoration: "underline",
            }}
          >
            Get in touch{" "}
          </a>
          with us and let us know how we can help
        </Text>
      </VStack>
    </>
  );
};

export default ExpiredVerificationComponent;
