import { React, useEffect, useRef, useState } from "react";

import {
  VStack,
  Heading,
  Image,
  Box,
  Spinner,
  Text,
  Button,
  Container,
  InputGroup,
  Slide,
  useDisclosure,
  Center,
  useToast,
  Divider,
  Tabs,
  TabList,
  Tab,
  Link,
  Skeleton,
  Progress,
  CircularProgress,
  FormControl,
  FormLabel,
  RadioGroup,
  Card,
  CardBody,
  Stack,
  Radio,
  Alert,
} from "@chakra-ui/react";
import { CheckIcon, ArrowRightIcon, CheckCircleIcon } from "@chakra-ui/icons";
import AMWLOGO from "../assets/amw/amw_logo.png";
import D1BANNER from "../assets/d1/d1-logo.png";
import FFD_BANNER from "../assets/amw/ffd.png";

import { firebaseApp, functions } from "../config/firebaseApp";
import { getStorage, ref } from "firebase/storage";
import { useUploadFile } from "react-firebase-hooks/storage";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import dayjs from "dayjs";
import { Form, useParams } from "react-router-dom";
import ExpiredVerificationComponent from "./ExpiredVerificationComponent";
import { httpsCallable } from "firebase/functions";

const storage = getStorage(firebaseApp);

const fileTypes = [
  "image/apng",
  "image/bmp",
  "image/gif",
  "image/jpeg",
  "image/pjpeg",
  "image/png",
  "image/svg+xml",
  "image/tiff",
  "image/webp",
  "image/x-icon",
];

function validFileType(type) {
  return fileTypes.includes(type);
}

function PrivacyPolicySlide() {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <Button disabled onClick={onToggle}>
        View privacy policy
      </Button>
      <Slide direction="bottom" in={isOpen} style={{ zIndex: 10 }}>
        <Box
          p="40px"
          color="white"
          mt="4"
          bg="teal.500"
          rounded="md"
          shadow="md"
        >
          <Text>
            Your privacy is important to us. Learn how we handle your data by
            reading our <u>Information Handling Policy</u>. The policy explains
            how we collect, handle and safeguard your personal information
          </Text>
        </Box>
      </Slide>
    </>
  );
}

const FileUpload = (props) => {
  const { label, accept, multiple, children, id, type, paymentLinkId } = props;
  const [tmpFileName, setTmpFileName] = useState("");
  const [tmpFile, setTmpFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);

  const inputRef = useRef(null);

  const toast = useToast();
  const [uploadFile, uploading, snapshot, error] = useUploadFile();
  //   const ref = storageRef(storage, "file.jpg");

  //   const { ref, ...rest } = register;
  const handleClick = (e) => {
    if (uploadComplete) {
      toast({
        title: "File has already been uploaded",
        status: "warning",
        duration: 3000,
        position: "top",
      });
    }
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    inputRef.current?.click();
  };

  const handleChange = () => {
    // console.log("change");
    // console.log(inputRef);

    setTmpFileName(inputRef.current.value);

    setTmpFile(URL.createObjectURL(inputRef.current.files[0]));
    handleUpload();
  };

  const handleUpload = async () => {
    if (!inputRef.current.files[0]) {
      toast({
        title: "Please select file / take photo before uploading",
        status: "error",
        duration: 3000,
      });
      return;
    }
    setIsUploading(true);
    const fl = inputRef.current.files[0];
    if (!validFileType(fl.type)) {
      toast({
        title: "Please upload a valid file type",
        status: "error",
        duration: 3000,
      });
      setIsUploading(false);
      return;
    }
    const dd = dayjs().unix();
    const fltype = fl.type.split("/");
    const spaceRef = ref(storage, `identity/${id}/${type}_${dd}.${fltype[1]}`);

    await uploadFile(spaceRef, fl, {
      contentType: fl.type,
      customMetadata: {
        verificationRequestId: id,
        fileType: type,
        paymentLinkId: paymentLinkId,
      },
    })
      .then((r) => {
        toast({
          title: "Uploaded successfully",
          status: "success",
          duration: 3000,
          position: "top",
        });
        setUploadComplete(true);
        props.uploaded(true);
        setIsUploading(false);
      })
      .catch((e) => {
        toast({
          title: "Upload failed",
          description: e.message,
          status: "error",
          duration: 3000,
          position: "top",
        });
        setUploadComplete(false);
        props.uploaded(false);
        setIsUploading(false);
      });
  };

  // useEffect(() => {
  //   console.log(snapshot);
  // }, [snapshot]);

  return (
    <Card>
      <CardBody>
        <VStack>
          <Heading
            size="md"
            leftIcon={
              uploading ? <Spinner /> : uploadComplete ? <CheckIcon /> : null
            }
            colorScheme="orange"
            variant="outline"
            // onClick={handleUpload}
            bottomMargin={15}
          >
            {uploading
              ? `Uploading ${label}`
              : uploadComplete
              ? `Uploaded ${label}`
              : null}
          </Heading>
        </VStack>
        <VStack>
          <Box
            h="200px"
            border="1px"
            borderColor="gray.200"
            bg="gray.50"
            borderRadius={10}
            onClick={handleClick}
            marginTop={5}
          >
            <Center>
              <Text padding={5}>
                <i>
                  Click here to upload: <b>{label}</b>
                </i>
              </Text>
              <InputGroup>
                <input
                  type={"file"}
                  multiple={multiple || false}
                  hidden
                  accept={accept}
                  ref={inputRef}
                  onChange={handleChange}
                  disabled={uploadComplete}
                />
                <VStack paddingTop={5} minH="120px" minW="200px">
                  <Image maxH="120px" src={tmpFile} />
                </VStack>
              </InputGroup>
            </Center>
          </Box>
        </VStack>
        <Progress
          marginBottom={10}
          marginTop={10}
          value={
            snapshot?.state === "running"
              ? Math.round(
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                )
              : 0
          }
          size="xs"
          colorScheme="whatsapp"
        />
      </CardBody>
    </Card>
  );
};

function VerificationComponent() {
  const [result, setResult] = useState(false);
  const [reqDocs, setReqDocs] = useState([]);
  const [readyForPayment, setReadyForPayment] = useState(false);
  const [accountUIConfig, setAccountUIConfig] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [insuranceCover, setInsuranceCover] = useState(null);
  const [verificationComplete, setVerificationComplete] = useState(false);
  const [paymentComplete, setPaymentComplete] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const toast = useToast();
  const paymentButtonRef = useRef(null);

  const [executeCallable, loading, error] = useHttpsCallable(
    functions,
    "getVerificationRequest"
  );

  const { token } = useParams();

  const getVeriReq = async () => {
    const result = await executeCallable(token);
    if (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 3000,
      });
      return;
    }
    setResult(result?.data);
  };

  const loadTest = () => {
    getVeriReq();
  };

  const handleSaveAndProceed = async () => {
    console.log("Save and proceed");
    if (!paymentMethod || !insuranceCover) {
      toast({
        title: "Please select payment method and insurance cover",
        status: "warning",
        duration: 3000,
      });
      return;
    }

    if (!readyForPayment) {
      toast({
        title: "Please upload all required documents before proceeding",
        status: "warning",
        duration: 3000,
      });
      return;
    }

    if (!token) {
      toast({
        title:
          "Invalid token, cannot save. Please click the link from your email message again",
        status: "error",
        duration: 3000,
      });

      return;
    }
    setIsSaving(true);

    const data = {
      paymentMethod: paymentMethod,
      insuranceCover: insuranceCover,
      docID: result?.id,
      token: token,
    };

    const updateVerificationRequest = httpsCallable(
      functions,
      "updateVerificationRequest"
    );

    await updateVerificationRequest({
      token: token,
      ...data,
    }).then((r) => {
      if (r.data) {
        toast({
          title: "Saved successfully",
          status: "success",
          duration: 3000,
        });

        window.open(`${result.paymentLink}`, "_blank");
        getVeriReq();
      } else {
        toast({
          title: "Error",
          description: r.data?.message,
          status: "error",
          duration: 3000,
        });
      }
    });
    setIsSaving(false);
  };

  const setAsUploaded = (idx) => {
    let tmpReqDocs = [...reqDocs];
    tmpReqDocs[idx]["uploaded"] = true;
    setReqDocs(tmpReqDocs);
  };

  useEffect(() => {
    if (token) {
      getVeriReq();
    }
  }, [token]);

  useEffect(() => {
    if (result) {
      if (result?.paymentComplete) {
        setPaymentComplete(true);
        let uiConfig = {
          brand: result?.account,
        };
        setAccountUIConfig(uiConfig);
      }
      if (result?.verificationComplete) {
        setVerificationComplete(true);
        let uiConfig = {
          brand: result?.account,
        };
        setAccountUIConfig(uiConfig);
        return;
      }
      if (result.paymentComplete) {
        return;
      }
      setReqDocs(result?.requiredDocuments);
      let uiConfig = {
        brand: result?.account,
      };
      setAccountUIConfig(uiConfig);
    }
  }, [result]);

  // Update ready for payment flag
  useEffect(() => {
    if (reqDocs && result) {
      let tmpReadyForPaymentDocs = true;
      reqDocs.forEach((rd) => {
        if (rd.uploaded) {
          tmpReadyForPaymentDocs = tmpReadyForPaymentDocs && true;
        } else {
          tmpReadyForPaymentDocs = tmpReadyForPaymentDocs && false;
        }
      });

      let tmpReadyForPaymentQuestion = true;
      if (paymentMethod?.length > 0 && insuranceCover?.length > 0) {
        tmpReadyForPaymentQuestion = tmpReadyForPaymentQuestion && true;
      } else {
        tmpReadyForPaymentQuestion = tmpReadyForPaymentQuestion && false;
      }

      const tmpReadyForPayment =
        tmpReadyForPaymentDocs && tmpReadyForPaymentQuestion;

      if (tmpReadyForPayment) {
        paymentButtonRef.current.scrollIntoView({ behavior: "smooth" });
      }
      setReadyForPayment(tmpReadyForPayment);
    }
  }, [JSON.stringify(reqDocs), paymentMethod, insuranceCover]);

  if (loading) {
    return (
      <VStack>
        <Center h="500px">
          <CircularProgress isIndeterminate size="100px" color="green.300" />
          <Text>Loading...</Text>
        </Center>
      </VStack>
    );
  }

  if (!result) {
    return <ExpiredVerificationComponent />;
  }
  if (verificationComplete || paymentComplete) {
    return (
      <VStack align="center" justify>
        {/* <pre>{JSON.stringify(reqDocs, null, 2)}</pre> */}
        {/* <Button onClick={loadTest}>Load test</Button> */}
        {/* <Box w="100px">
      <Image src={AMWLOGO} alt="AMW Logo" fallback={<Spinner />} />
      </Box> */}
        <Image
          src={accountUIConfig?.brand === "D1NSW" ? D1BANNER : FFD_BANNER}
          alt="AMW Logo"
          fallback={<Spinner />}
        />
        <Heading>
          {result ? `Hi ${result?.firstName}` : <Skeleton height="20px" />}
        </Heading>

        <Container paddingTop={5}>
          <Text
            textAlign="left"
            mb="2"
            style={{ padding: 15, fontWeight: 800 }}
          >
            Welcome to the{" "}
            {accountUIConfig?.brand === "AMW"
              ? "Auto Mega Warehouse"
              : "Dutton One"}{" "}
            self service portal.
            <br />
            <br /> This portal is designed to streamline the process of
            verifying your identity and making a deposit.
          </Text>
          <Heading
            textAlign="left"
            style={{ color: "#2E86C1", fontSize: "1.2em", paddingBottom: 15 }}
          >
            Status:
          </Heading>
          <Heading
            textAlign="left"
            style={{
              color: "#2E86C1",
              fontSize: "1.2em",
              paddingBottom: 15,
              paddingLeft: 15,
            }}
          >
            {verificationComplete && "Verification complete "}
            {verificationComplete && <CheckCircleIcon color="green" />}
          </Heading>
          <Heading
            textAlign="left"
            style={{
              color: "#2E86C1",
              fontSize: "1.2em",
              paddingBottom: 15,
              paddingLeft: 15,
            }}
          >
            {paymentComplete && "Payment complete "}
            {paymentComplete && <CheckCircleIcon color="green" />}
          </Heading>

          <Text
            textAlign="left"
            mb="2"
            style={{ color: "#5DADE2", fontWeight: 500, fontSize: "1.2em" }}
          >
            {verificationComplete && paymentComplete && (
              <>
                Your verification and payment steps are complete.
                <br />
                <br />
                <b>Congratulations! </b>Your car is now secured! Your sales
                consultant will be in touch with you soon.
              </>
            )}
            {verificationComplete && !paymentComplete && (
              <>
                Your verification step is complete. You can now proceed to the
                next step which is placing your deposit.
              </>
            )}
          </Text>
          {verificationComplete && !paymentComplete && (
            <Button
              marginTop={5}
              marginBottom={30}
              padding={5}
              colorScheme="green"
              variant="outline"
              width="100%"
              rightIcon={<ArrowRightIcon />}
              onClick={() => {
                window.open(`${result.paymentLink}`, "_blank");
              }}
            >
              Proceed to deposit payment screen
            </Button>
          )}
        </Container>
      </VStack>
    );
  }

  return (
    <VStack align="center" justify>
      {/* <pre>{JSON.stringify(reqDocs, null, 2)}</pre> */}
      {/* <Button onClick={loadTest}>Load test</Button> */}
      {/* <Box w="100px">
      <Image src={AMWLOGO} alt="AMW Logo" fallback={<Spinner />} />
      </Box> */}
      <Image
        src={accountUIConfig?.brand === "D1NSW" ? D1BANNER : FFD_BANNER}
        alt="AMW Logo"
        fallback={<Spinner />}
      />
      <Heading>
        {result ? `Hi ${result?.firstName}` : <Skeleton height="20px" />}
      </Heading>

      <Container paddingTop={5}>
        <Text textAlign="left" mb="2" style={{ padding: 15, fontWeight: 800 }}>
          Welcome to the{" "}
          {accountUIConfig?.brand === "AMW"
            ? "Auto Mega Warehouse"
            : "Dutton One"}{" "}
          self service portal.
          <br />
          <br /> This portal is designed to streamline the process of verifying
          your identity and making a deposit.
        </Text>
        <Text textAlign={"left"} mb="2" style={{ padding: 15 }}>
          <ol
            style={{
              // no bullets
              listStyleType: "none",
            }}
          >
            <strong>Here's how it works:</strong>
            <br />
            <br />
            <li>
              <strong>Document Upload and Verification:</strong> You will be
              prompted to upload specific documents needed for identity
              verification. These documents may include a valid ID, proof of
              address, and any other required documentation.
            </li>
            <br />

            <li>
              <strong>Deposit Placement:</strong> After all your documents are
              successfully uploaded, you will be able to proceed with placing
              your deposit. This step completes the process, securing your car!
            </li>
          </ol>
        </Text>
        <Text textAlign="left" mb="2" style={{ padding: 15 }}>
          Should you have any questions or encounter any issues during this
          process, our support team is available to assist you.
        </Text>
      </Container>
      <Container paddingTop={5}>
        <Text
          textAlign="left"
          mb="2"
          style={{ color: "#5DADE2", fontWeight: 500 }}
        >
          Please upload the following documents to verify your identity.
        </Text>
        <Text
          textAlign="left"
          mb="2"
          style={{ color: "#5DADE2", fontWeight: 500 }}
        >
          Once all documents are uploaded, you can proceed with placing your
          deposit.
        </Text>
      </Container>
      <Tabs variant="soft-rounded" colorScheme="green">
        <TabList>
          <Tab>Verification</Tab>
          <Tab isDisabled>Deposit payment</Tab>
        </TabList>
      </Tabs>

      <Container paddingTop={5}>
        <Heading textAlign="center" style={{ color: "#2E86C1" }}>
          Upload area
        </Heading>
        {loading ? (
          <>
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
          </>
        ) : null}
        {result?.requiredDocuments?.map((rd, idx) => {
          return (
            <Container key={idx} paddingTop={5}>
              <FileUpload
                accept="image/*,.pdf"
                multiple={false}
                label={rd.name}
                type={rd.type}
                uploaded={() => setAsUploaded(idx)}
                id={result?.id}
                paymentLinkId={result?.paymentLinkId}
                //   register={register("file_", { validate: validateFiles })}
              ></FileUpload>
            </Container>
          );
        })}
      </Container>

      <Container paddingTop={5}>
        <Heading textAlign="left" size="md" mb="2" style={{ color: "#5DADE2" }}>
          A few additional questions:
        </Heading>
        <Card
          mb="2"
          style={{
            border: paymentMethod?.length > 0 ? null : "1px solid orange",
          }}
        >
          <CardBody>
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="consent" mb="2">
                How are you planning to pay the remaining <b>balance</b>?
              </FormLabel>
              <RadioGroup
                size="lg"
                id="balance"
                onChange={(e) => {
                  setPaymentMethod(e);
                }}
              >
                <Stack direction="column">
                  <Radio value="cash">Cash/EFT</Radio>
                  <Radio value="finance">Finance</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
          </CardBody>
        </Card>
        <Card
          md="2"
          style={{
            border: insuranceCover?.length > 0 ? null : "1px solid orange",
          }}
        >
          <CardBody>
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="consent" mb="2">
                Would you like to receive a comprehensive insurance quote from
                Allianz & Suncorp?
              </FormLabel>
              <RadioGroup
                size="lg"
                id="insurance"
                onChange={(e) => {
                  setInsuranceCover(e);
                }}
              >
                <Stack direction="column">
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
          </CardBody>
        </Card>
      </Container>
      <Divider />
      <Container paddingTop={5}>
        <Heading textAlign="center" style={{ color: "#2E86C1" }}>
          Next step:
        </Heading>

        <Button
          marginTop={5}
          marginBottom={30}
          padding={5}
          colorScheme="green"
          variant="outline"
          width="100%"
          isDisabled={!readyForPayment}
          ref={paymentButtonRef}
          rightIcon={<ArrowRightIcon />}
          onClick={handleSaveAndProceed}
          isLoading={isSaving}
        >
          Save and proceed to deposit payment screen
        </Button>
      </Container>

      {/* <Container paddingTop={5}>
        <PrivacyPolicySlide />
      </Container> */}
    </VStack>
  );
}

export default VerificationComponent;
