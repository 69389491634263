import { ChakraProvider, Spinner } from "@chakra-ui/react";
import { RouterProvider } from "react-router-dom";
import { router } from "./config/router";

function App() {
  return (
    <ChakraProvider>
      <RouterProvider fallbackElement={<Spinner />} router={router} />
    </ChakraProvider>
  );
}

export default App;
